@font-face {
  font-family: 'Badaboom';
  src: url('../../fonts/badaboom.woff2') format('woff2'), url('../../fonts/badaboom.woff') format('woff');
}

@font-face {
  font-family: 'ThirstyCaps';
  src: url('../../fonts/ThirstyCaps.otf');
};

@font-face {
  font-family: 'SkateboardGirl';
  src: url('../../fonts/SkateboardGirl.ttf');
};


@font-face {
  font-family: 'amsterdam';
  src: url('../../fonts/amsterdam.ttf');
};
