.games-list {
  &__title {
    margin: 15px 0;
    // color: #ff0;
    color: #32cd32;
    font-size: 32px;
    font-family: 'Badaboom', sans-serif;
    letter-spacing: 0.065em;
    text-align: center;
  }
}
