@import 'src/assets/styles/abstracts/mixins';
@import 'src/assets/styles/abstracts/variables';

.footer {
  margin: 25px 0;
  text-align: center;
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 12px;

  span {
    padding: 2px 0;
    display: block;
    // color: #ff0;
    color: white;
    font-weight: 700;
  }
}
