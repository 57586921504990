@import 'src/assets/styles/abstracts/mixins';
@import 'src/assets/styles/abstracts/variables';

.hero-profile {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hero-title {
  text-align: center;
}

.hero-section {
  margin: 8px 0;
  max-width: 320px;
  padding: 16px;
  @include card();

  &__title {
    margin: 8px 0;
    text-align: center;
    @include title();
  }

  &--avatar {
    text-align: center;
  }
}
