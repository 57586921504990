.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.walletButton {
  padding: 10px 15px;
  font-size: 18px;
  margin: 0 5px;
}

.message {
  margin: 20px;
  // color: #ff0;
  color: #32cd32;
  font-size: 16px;
  text-align: center;
}
