.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin: 10px;
  // color: #ff0;
  color: #32cd32;
  font-size: 16px;
  text-align: center;
}

.convertSection {
  margin: 10px 0;
  // color: #ff0;
  color: #32cd32;
  font-size: 16px;
  text-align: center;
}

.button {
  font-size: 18px;
  margin-top: 15px;
  padding: 10px 15px;
}
